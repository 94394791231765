@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../public/assets/font/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}
